import Script from 'next/script';

import useRouterHook from '@/hooks/useRouterHook.hook';
import useBrandConfigStore from '@/context/store/useBrandConfigStore.store';
import useAuth from '@/features/auth/useAuth';

function ScriptLayout() {
	const { router } = useRouterHook();
	const { brandConfig } = useBrandConfigStore((state) => ({
		brandConfig: state.config
	}));
	const { loginToken } = useAuth();

	return (
		<>
			{brandConfig.script && loginToken && !loginToken.toLowerCase().includes('sessiontoken') && (
				<>
					<Script id="google-tag-manager" strategy="afterInteractive">
						{brandConfig?.scriptSetting?.googleTagManager?.script}
					</Script>
					<noscript>
						<iframe
							src="https://www.googletagmanager.com/ns.html?id=GTM-WSCLTJS"
							height="0"
							width="0"
							style={{ display: 'none', visibility: 'hidden' }}></iframe>
					</noscript>
				</>
			)}
			{router.pathname !== '/play' && brandConfig?.scriptSetting?.livechat?.script ? (
				<div
					id="live-chat-id"
					dangerouslySetInnerHTML={{
						__html: brandConfig?.scriptSetting?.livechat?.script
					}}></div>
			) : null}
			{router.pathname !== '/play' && brandConfig?.scriptSetting?.customScript ? (
				<div
					id="custom-script-id"
					dangerouslySetInnerHTML={{
						__html: brandConfig?.scriptSetting?.customScript
					}}></div>
			) : null}
		</>
	);
}

export default ScriptLayout;
